import { render, staticRenderFns } from "./QuantRuleSave.vue?vue&type=template&id=79ff4dc7"
import script from "./QuantRuleSave.vue?vue&type=script&lang=js"
export * from "./QuantRuleSave.vue?vue&type=script&lang=js"
import style0 from "./QuantRuleSave.vue?vue&type=style&index=0&id=79ff4dc7&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports