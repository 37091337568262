<template>
<div class="my-quant-rules">
  <v-row class="flex-child">
  <!-- side bar -->
  <v-col class="d-flex" cols="2">
    <v-navigation-drawer
      absolute
      permanent
      left
      class="navi"
    >
      <v-list>
      <v-list-group
        prepend-icon="mdi-account-multiple-outline"
        no-action
        sub-group
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>전문가 퀀트공식</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, i) in expertRules"
          :key="i"
          :link="true"
          @click="get_rules(item, 'expert')"
        >
          <v-list-item-title v-text="item[1]"></v-list-item-title>

        </v-list-item>
      </v-list-group>

      <v-list-group
        prepend-icon="mdi-account"
        no-action
        sub-group        
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>나의 퀀트공식</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, i) in myRules"
          :key="i"
          :link="true"
          @click="get_rules(item, 'my')"
        >
          <v-list-item-title v-text="item[1]"></v-list-item-title>
        </v-list-item>
      </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </v-col> <!-- side bar -->

<!-- sheet -->
  <v-col cols="9" class="main-sheet" align-self="center" v-if="sheetShow">
    <div class="container mt-4" v-if="ruleShow">
    <v-subheader subheader-left-padding> {{ title }} </v-subheader>    
    <v-row>    
      <!-- 시장 -->
        <v-col cols="4" id="market-menu">            
            <v-radio-group v-model="market" row label="시장">   
                <v-radio label="ANY" value="ANY"></v-radio>
                <v-radio label="KOSPI" value="KOSPI"></v-radio>
                <v-radio label="KOSDAQ" value="KOSDAQ"></v-radio>
            </v-radio-group> 
        </v-col>

        <!-- 시총 -->
        <v-col cols="4" id="market-cap-menu" align-self="end">  
            <v-slider v-model="market_cap" :thumb-size="34" thumb-label="always" min="1" max="10000" label="시총"> </v-slider>            
              {{ market_cap }} 천억원 이하
        </v-col>
        
        <!-- 업종 -->
        <v-col cols="4" id="sector-menu">
            <v-combobox v-model="sector" :items="sector_options" label="업종" multiple clearable small-chips>
            </v-combobox>
        </v-col>
    </v-row>
    </div>    

    <!-- Rule Table -->
    <v-row align="center">
      <div class="container mt-4" id="table" v-if="ruleShow">
      <v-data-table
        :headers="ruleHeaders"
        :items="rules"
        :items-per-page="10"
        :hide-default-footer=true
        class="elevation-1"
        dense
      >      
        <template v-slot:item.min="props">
            <v-edit-dialog
            :return-value.sync="props.item.min"
            large
            persistent
            >
            <div>{{ props.item.min }}</div>
            <template v-slot:input>
                <div class="mt-4 text-h6">
                최소값
                </div>
                <v-text-field
                v-model="props.item.min"
                label="Edit"
                single-line
                counter="number"
                autofocus
                ></v-text-field>
            </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.max="props">
            <v-edit-dialog
            :return-value.sync="props.item.max"
            large
            persistent
            >
            <div>{{ props.item.max }}</div>
            <template v-slot:input>
                <div class="mt-4 text-h6">
                최대값
                </div>
                <v-text-field                
                v-model="props.item.max"
                label="Edit"
                single-line
                counter="number"
                autofocus
                ></v-text-field>
            </template>
            </v-edit-dialog>
        </template>
        <template v-slot:item.ratio="props">
            <v-edit-dialog
            :return-value.sync="props.item.ratio"
            large
            persistent
            @save="ratio_save"
            >
            <div>{{ props.item.ratio }}</div>
            <template v-slot:input>
                <div class="mt-4 text-h6">
                비중
                </div>
                <v-text-field
                v-model="props.item.ratio"
                single-line
                counter
                autofocus
                ></v-text-field>
            </template>
            </v-edit-dialog>
        </template>
      </v-data-table>
      </div>
    </v-row>
  
  <br>

  <!-- 백테스트 버튼 -->    
  <v-row align="center" justify="space-around" >
    <template>
      <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      >
      <template v-slot:activator="{ on, attrs }">
          <v-btn
          v-bind="attrs"
          v-on="on"
          dark
          v-if="ruleShow"
          >
          백 테스트
          </v-btn>
      </template>
      <v-card>
          <v-card-title>
          <span class="text-h5">{{ title }}</span>
          </v-card-title>
          <v-card-text>
          <v-container>                        
              <v-row>
              <v-col cols="3">
                <v-text-field
                  label="초기투자금액(만원)*" required                            
                  v-model="amount_selected"
                ></v-text-field>
              </v-col>
              
              <v-col cols="3">
                <v-text-field
                  label="편입종목수*" required                            
                  v-model="stock_selected"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="테스트기간(년)*" required                            
                  v-model="time_selected"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-select
                  :items="tradeTerms"
                  :menu-props="{ top: true, offsetY: true }"
                  label="매매주기*" required
                  v-model="term_selected"
                ></v-select>                        
              </v-col>
              </v-row>                        
              <v-row align="center" class="mx-0">
              <small>
                * 매매주기 <br>
                  - 월  : 매월말 <br> 
                  - 분기: 3월말(4분기), 5월말(1분기), 8월말(2분기), 11월말 (3분기) <br>
                  - 반기: 5월말(1분기), 11월말 (3분기) <br>
                  - 연간: 3월말(4분기) <br>
                  - 시즌제: 올해 매수싯점(월말) ~ 다음해 매도싯점(월말)		
              </small>          
            </v-row>
          </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">닫기</v-btn>
          <v-btn color="blue darken-1" text @click="backTest()">백테스트 시작</v-btn>
          </v-card-actions>   
      </v-card>
      </v-dialog>
    </template>
  </v-row>

  <!-- 백테스트 결과 -->
  <div class="backtest-result" v-if="testShow">
  <v-row class="d-flex" justify="space-around" >
    <v-col cols=12 ><h2> 백 테스트 결과 </h2></v-col>    
  </v-row>  
  <v-row class="d-flex" justify="space-around">
    <v-col><v-btn class="ma-2" outlined color="indigo" @click="go_to_back()"> 뒤로 가기 </v-btn></v-col>
    <v-col><v-btn v-if="isExpert" class="ma-2" outlined color="indigo" @click="saveDialog = true"> 나의 퀀트공식에 추가 </v-btn></v-col>
    <v-dialog max-width="600" v-model="saveDialog">
        <QuantRuleSave
          :myQuantRules = "rules"
          :market_selected = "market"
          :market_cap_selected = "market_cap"
          :sector_selected = "sector"
          @hide="saveDialog = false"
          @submit="put_my_quant_rules()"
        >    
          <template v-slot:title>
            <v-text-field label="제목" hint="제목을 변경하세요" persistent-hint v-model="title"> 
              {{ title }} 
            </v-text-field>             
          </template>
        </QuantRuleSave>
    </v-dialog>
    <v-col><v-btn class="ma-2" outlined color="indigo" to="/backtest_detail">세부 내역</v-btn></v-col>     
  </v-row>
  <br>

  <v-card elevation="2">
    <v-card-title> {{ myQuantTitle }} </v-card-title>
    <v-row class="d-flex" >
      <v-col> 누적 수익율(%): 
        <v-chip :color="getCumColor(cum_yield)" dark >
            {{ cum_yield }}
        </v-chip>
      </v-col>
      <v-col> 평균 수익율(%): 
        <v-chip :color="getAvgColor(avg_yield)" dark >
            {{ avg_yield }}
        </v-chip>
      </v-col>            
    </v-row>    
  </v-card>
  <v-row class="d-flex"  align="center">
  <v-col cols="12">
    <v-data-table
      :headers="month_yield_headers"
      :items="month_yield_lists"
      :items-per-page="10"
      class="elevation-1"
      :hide-default-footer=true
      :loading="loadTable"
      loading-text="Loading... Please wait"
    ></v-data-table>    
  </v-col>
  </v-row>
  
  <!-- 그래프 -->
  <v-row class="d-flex" >
    <template>
        <div class="container">
            <line-chart
            v-if="chartLoaded"
            :chartData="datacollection"/>
        </div>
    </template>
  </v-row>
  <template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ notifyText }}
    </v-snackbar>
  </template>
</div> <!-- backtest 결과 --> 

</v-col>
</v-row>
</div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';
import LineChart from './LineChart.js';
import QuantRuleSave from './QuantRuleSave.vue';

export default {
    name: "MyQuantRule",
    components: { LineChart, QuantRuleSave },
    data() {
        return {
          menu: "myquantrule",
          expertRules: [],
          myRules: [],                   
          
          title: null,
          market: null,
          market_cap: null,
          sector: [],
          rules: [],

          selectedQuant: [],
          myQuants: [],
          myQuantRules: [],
          saveQuantRules: [],
          ruleHeaders: [
            {
                text: '항목',
                align: 'start',
                sortable: false,
                value: 'category',
            },
            { text: '지표', value: 'text' },
            { text: '망소/망대', value: 'flag' },
            { text: '최소값(입력)', value: 'min' },
            { text: '최대값(입력)', value: 'max' },
            { text: '비중(%)(입력)', value: 'ratio' },
          ],
          sheetShow: false,
          ruleShow: true,
          dialog: false,
          market_selected: 'ANY',
          market_cap_selected: 10000,
          sector_options: ['전체', '비금속', '운송장비·부품', '숙박·음식', '섬유·의류', '의료정밀', '통신장비', '기계·장비', '건설업', '음식료·담배', '비금속광물', '통신업',
                          '컴퓨터서비스', '기타금융','서비스업','일반전기전자','전기전자','반도체','전기가스업','유통','정보기기','운수창고업','화학','음식료품','기타제조업',
                          '방송서비스','보험','유통업','금속','건설','증권','철강금속','금융','오락·문화','운송','기계','기타서비스','기타제조','운수장비','전기·가스·수도',
                          '인터넷','종이·목재','디지털컨텐츠','제약','광업','통신서비스','IT부품','소프트웨어','농업, 임업 및 어업','은행','출판·매체복제','의약품','섬유의복',
                          '종이목재','의료·정밀기기',
                          ],
          sector_selected: ['전체'],            
          quant_id: null,
          tradeTerms: ['월','분기','반기','년','시즌'],
          amount_selected: null,
          stock_selected: null,
          time_selected: null,
          term_selected: null,
          backtestQuaryData: null,
          backtestResult: null,
          testShow: false,
          loadTable: true,
          progressBar: true,
          cum_yield: null,
          avg_yield: null,
          month_yield_headers: [],
          month_yield_lists: [],
          myQuantTitle: null,

          saveDialog: false,   
          saveAlertShow: false,       
          
          chartLoaded: false,
          datacollection: null,

          isExpert: false,

          snackbar: false,
          notifyText: '저장되었습니다.',
          timeout: 3000,

        }
    },

    computed: {
        ...mapState(['user']) 
    },

    created () {     
      this.get_my_quants(); 
    },
    
    methods: {      
      
      get_my_quants() {
        var vm = this;
        const jwt = localStorage.getItem('jwt');
        const headers = {"Authorization": "jwt "+ jwt};
      
        var rules = [];
        const req_data = {user: this.user}
        axios.post('/api/quant/quant/', req_data, {headers})
                .then(function(res) {
                    console.log("get_my_quants() GET RES", res);
                    rules = res.data;                    
                    vm.expertRules = rules['expert'];
                    vm.myRules = rules['my'];
                })
                .catch(function (err) {
                    console.log("get_my_quants() GET ERR", err);
                });

      },

      put_my_quant_rules () {
        this.saveQuantRules = { user: this.user, title: this.title, market: this.market, 
                        market_cap: this.market_cap, sector: this.sector, search_items: this.rules };
        var vm = this;
        const jwt = localStorage.getItem('jwt');
        const headers = {"Authorization": "jwt "+ jwt};
      
        axios.post('/api/quant/save/', this.saveQuantRules, {headers})
            .then(function(res) {
                console.log("POST RES", res);
                vm.saveDialog = false;
                vm.snackbar = true;
                vm.notifyText = "저장되었습니다."
            })
            .catch(function (err) {
                console.log("POST ERR", err);
            });
      },
    
      get_rules(item, flag) {      
        if (flag == 'expert') {
          this.isExpert = true;
        }
        this.sheetShow = true;     
        this.testShow = false;     
        this.quant_id = item[0]; 
        this.title = item[1]; 
        this.market = item[2];
        this.market_cap = item[3];
        this.sector = item[4];
        var vm = this;
        const jwt = localStorage.getItem('jwt');
        const headers = {"Authorization": "jwt "+ jwt};
      
        const req_data = {user: this.user}
        axios.post('/api/quant/rule/'+item[0]+'/', req_data, {headers})
                .then(function(res) {
                    console.log("get_rules() GET RES", res);
                    var myQuants = {};
                    myQuants = res.data;
                    vm.rules = myQuants['rules'];                    
                })
                .catch(function (err) {
                    console.log("get_rules() GET ERR", err);
                }); 
      },

      backTest() {
        this.dialog = false;
        this.ruleShow = false;
        this.testShow = true;  
        this.loadTable = true;     
        this.loadedChart = false 
                  
        this.backtestQuaryData = { 'user': this.user, 'quant_id': this.quant_id, 'market': this.market, 
                          'market_cap': this.market_cap, 'sector': this.sector, 'amount': this.amount_selected,
                          'stocks': this.stock_selected, 'time': this.time_selected, 'term': this.term_selected };
        var vm = this;        
        const jwt = localStorage.getItem('jwt');
        const headers = {"Authorization": "jwt "+ jwt};
        
        axios.post('/api/quant/backtest/', this.backtestQuaryData, {headers})
              .then(function(res) {
                  console.log("BackTest: POST RES", res);
                  vm.backtestResult = res.data;
                  vm.cum_yield = vm.backtestResult['cum_yield'];
                  vm.avg_yield = vm.backtestResult['avg_yield'];
                  vm.month_yield_headers = vm.backtestResult['month_yield_headers'];
                  vm.month_yield_lists = vm.backtestResult['month_yield_lists'];
                  vm.loadTable = false;

                  // chart data
                  var v_borderColor = ['#BAFB04', '#16F40A', '#05BDF8', '#0B37FA', '#F4A7B9', '#F90E45'];
                  var v_labels = [];
                  var v_datasets = [];
                  vm.month_yield_headers.forEach(function(item){
                    v_labels.push(item['text']);   
                  });
                  v_labels.shift();
                  
                  var i = 0;
                  vm.month_yield_lists.forEach(function(item){
                    var data_tmp = Object.values(item);
                    data_tmp.splice(0, 1);
                    v_datasets.push({ data: data_tmp, label: item['0'], borderColor: v_borderColor[i], fill: false });
                    i++;  
                  });
                  vm.datacollection = { labels: v_labels, datasets: v_datasets};
                  vm.chartLoaded = true;
              })
              .catch(function (err) {
                    console.log("BackTest: POST ERR", err);
              });  
      },

      go_to_back() {        
        this.testShow = false;
        this.ruleShow = true;
      },

      getCumColor (cum_yield) {
        if (cum_yield < 0 ) return 'red'
        else return 'green'
      },
      getAvgColor (cum_yield) {
        if (cum_yield < 0 ) return 'red'
        else return 'green'
      },

      ratio_save() {
      this.sumRatio= this.quant_selected.map(item => item.ratio).reduce((prev, curr) => prev + curr, 0);
      if (this.sumRatio !== 100) {
          this.snack = true
          this.snackColor = 'error'
          this.snackText = '비중 합계는 100%로 설정하세요.'
      }
  },


    },
    
  }
</script>


<style>
.navi{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 140px;
}
.main-sheet{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 140px;
}
</style>

