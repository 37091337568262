<template>
    <v-card>
        <v-card-title>
        <span class="text-h5">나의 퀀트공식에 추가</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
            <v-col cols="12">
              <span v-for="(item, index) in myQuantRules" :key="index">  
                  <v-chip class="ma-2" small> {{ item.text }} </v-chip>    
              </span>
            </v-col>
            <v-col cols="12">               
              <slot name="title">
                {{  }}
              </slot>
            </v-col>
            <v-col cols="6">
                <v-text-field
                v-model="market_selected"
                label="시장"
                disabled
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                v-model="market_cap_selected"
                label="시총"
                disabled
                ></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-text-field
                v-model="sector_selected"
                label="업종"
                disabled
                ></v-text-field>
            </v-col>                            
            </v-row>
        </v-container>
        <small>*필수 입력 항목</small>
        </v-card-text>
        <v-card-actions class="justify-end mr-2 pb-4">
          <v-btn color="amber" dark rounded small @click="$emit('hide')"> 닫 기 </v-btn>
          <v-btn color="success" rounded small @click="$emit('submit')" > 저 장 </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "QuantRuleSave",
    props: [ 'myQuantRules', 'market_selected', 'market_cap_selected', 'sector_selected'],
}
</script>

<style>
.quant-rule-save{
   font-family: 'Source Sans Pro', 'Noto Sans KR script=latin rev=3';
   margin-top: 140px;
}
</style>
